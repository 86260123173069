<template>
  <v-list
    outlined
  >
    <template v-for="(company, index) in companies">
      <v-list-item
        :key="company.id"
        @click="promoteUser(company)"
      >
        <v-list-item-avatar
          color="primary"
        >
          <span class="white--text text-h5">T</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ company.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider
        v-if="index !== companies.length - 1"
        :key="index"
      />
    </template>
  </v-list>
</template>

<script>
import { Company } from '@/store/models'
import { mapActions } from 'vuex'

export default {
  name: 'CompanyList',
  computed: {
    companies () {
      return Company.all()
    }
  },
  mounted () {
    if (this.companies.length === 1) {
      const company = this.companies[0]
      this.promoteUser(company)
    }
  },
  methods: {
    ...mapActions('auth', ['refresh']),
    ...mapActions('app', ['getMe']),
    async promoteUser (data) {
      const response = await this.refresh({ company: data.id })
      if (response.status === 200) {
        await Promise.all([
          this.getMe(),
          this.$router.push({ name: 'App-Dashboard' })
        ])
      }
    }
  }
}
</script>

<style scoped>

</style>
