<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <CompanyList v-if="hasCompany" />
        <CompanyForm v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Company } from '@/store/models'
import CompanyList from '@/pages/App/Companies/__CompanyList__'
import CompanyForm from '@/pages/App/Companies/__CompanyForm__'

export default {
  name: 'Companies',
  layout: 'empty',
  components: { CompanyList, CompanyForm },
  computed: {
    hasCompany () {
      return Company.exists()
    }
  }
}
</script>
