<template>
  <v-card flat>
    <v-card-title class="justify-center">
      Create a company
    </v-card-title>
    <v-form v-model="form">
      <v-card-text>
        <v-text-field
          v-model="fields.name"
          label="Name"
          name="name"
          :rules="[rules.required]"
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          :disabled="!form"
          color="primary"
          @click="create(fields)"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { Company } from '@/store/models'

export default {
  name: 'CompanyForm',
  mixins: [formMixin],
  methods: {
    async create (data) {
      await Company.api().post(null, data)
    }
  }
}
</script>
